var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _DidomiConsentHandler_lpauth, _DidomiConsentHandler_adsPurposeId, _DidomiConsentHandler_philanthropyPurposeId;
import DidomiInitSDK from "./DidomiSDK";
class DidomiConsentHandler {
    constructor(publicApiKey, noticeId, adsPurposeId, philanthropyPurposeId, secretId, syncEnabled, syncFrequency, userId, userIdAuthDigest, lpauth) {
        _DidomiConsentHandler_lpauth.set(this, void 0);
        _DidomiConsentHandler_adsPurposeId.set(this, void 0);
        _DidomiConsentHandler_philanthropyPurposeId.set(this, void 0);
        __classPrivateFieldSet(this, _DidomiConsentHandler_lpauth, lpauth, "f");
        __classPrivateFieldSet(this, _DidomiConsentHandler_adsPurposeId, adsPurposeId, "f");
        __classPrivateFieldSet(this, _DidomiConsentHandler_philanthropyPurposeId, philanthropyPurposeId, "f");
        window.didomiConfig = {
            user: {
                organizationUserId: userId,
                organizationUserIdAuthDigest: userIdAuthDigest,
                organizationUserIdAuthSid: secretId,
                organizationUserIdAuthAlgorithm: "hash-md5",
            },
            sync: {
                enabled: syncEnabled,
                frequency: syncFrequency,
                delayNotice: true,
            }
        };
        DidomiInitSDK(publicApiKey, noticeId);
        this.initListeners();
    }
    getCurrentConsent() {
        if (!window.Didomi) {
            return {
                "advertising": null,
                "productAndPhilanthropy": null,
            };
        }
        function getConsentFromPurposeAndVendor(purpose, vendor) {
            if ((purpose === undefined || purpose === null) && (vendor === undefined || vendor === null)) {
                return null;
            }
            return purpose === true && vendor === true;
        }
        const advertisingPurpose = window.Didomi.getUserConsentStatusForPurpose("publicitaire");
        const advertisingVendor = window.Didomi.getUserConsentStatusForVendor("c:partenaires-publicitaire");
        const productAndPhilanthropyPurpose = window.Didomi.getUserConsentStatusForPurpose("philanthropie");
        const productAndPhilanthropyVendor = window.Didomi.getUserConsentStatusForVendor("c:partenaires-philanthropie");
        return {
            advertising: getConsentFromPurposeAndVendor(advertisingPurpose, advertisingVendor),
            productAndPhilanthropy: getConsentFromPurposeAndVendor(productAndPhilanthropyPurpose, productAndPhilanthropyVendor),
        };
    }
    initListeners() {
        window.didomiEventListeners = window.didomiEventListeners || [];
        window.didomiEventListeners.push({
            event: "notice.shown",
            listener: function () {
                document.getElementById("didomi-host").setAttribute("data-location", "consentPopup");
            },
        });
        __classPrivateFieldGet(this, _DidomiConsentHandler_lpauth, "f").onAuthStateChanged(() => {
            if (!__classPrivateFieldGet(this, _DidomiConsentHandler_lpauth, "f").isLoggedIn()) {
                window.Didomi && window.Didomi.reset();
            }
        });
    }
    onConsentChanged(callback) {
        window.didomiEventListeners = window.didomiEventListeners || [];
        window.didomiEventListeners.push({
            event: "consent.changed",
            listener: () => {
                try {
                    callback(this.getCurrentConsent());
                }
                catch (e) {
                    console.error(e);
                }
            },
        });
    }
    onReady(callback) {
        window.didomiOnReady = window.didomiOnReady || [];
        window.didomiOnReady.push(() => {
            callback(this.getCurrentConsent());
        });
    }
    show() {
        window.Didomi.preferences.show();
    }
}
_DidomiConsentHandler_lpauth = new WeakMap(), _DidomiConsentHandler_adsPurposeId = new WeakMap(), _DidomiConsentHandler_philanthropyPurposeId = new WeakMap();
export default DidomiConsentHandler;
