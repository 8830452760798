export default class {
    constructor() { }
    getCurrentConsent() {
        return {
            "advertising": null,
            "productAndPhilanthropy": null,
        };
    }
    onReady(callback) {
        callback({
            "advertising": null,
            "productAndPhilanthropy": null,
        });
    }
    onConsentChanged() {
        return;
    }
    show() {
        return;
    }
}
