var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _BootstrapConsent_dataLayer, _BootstrapConsent_analytics;
import CachedConsentHandler from "./Consent/CachedConsentHandler";
import CachedLPAuth from "./Consent/CachedLPAuth";
import DidomiConsentHandler from "./Consent/DidomiConsentHandler";
import NullConsentHandler from "./Consent/NullConsentHandler";
export class BootstrapConsent {
    constructor(didomiIntegrationEnabled, didomiPublicApiKey, didomiNoticeId, didomiAdsPurposeId, didomiPhilanthropyPurposeId, didomiSecretId, didomiSyncEnabled, didomiSyncFrequency, lpauth, dataLayer, analytics) {
        _BootstrapConsent_dataLayer.set(this, void 0);
        _BootstrapConsent_analytics.set(this, void 0);
        let userIdAuthDigest = null;
        let userId = null;
        if (didomiIntegrationEnabled) {
            const cachedLPAuth = new CachedLPAuth(lpauth);
            userId = cachedLPAuth.getUserId();
            userIdAuthDigest = cachedLPAuth.getUserIdAuthDigest();
        }
        __classPrivateFieldSet(this, _BootstrapConsent_dataLayer, dataLayer, "f");
        __classPrivateFieldSet(this, _BootstrapConsent_analytics, analytics, "f");
        if (userId && userIdAuthDigest) {
            this.consentHandler = new CachedConsentHandler(new DidomiConsentHandler(didomiPublicApiKey, didomiNoticeId, didomiAdsPurposeId, didomiPhilanthropyPurposeId, didomiSecretId, didomiSyncEnabled, didomiSyncFrequency, userId, userIdAuthDigest, lpauth));
        }
        else {
            this.consentHandler = new CachedConsentHandler(new NullConsentHandler());
        }
        this.initListeners();
    }
    initListeners() {
        this.consentHandler.onReady((consent) => {
            __classPrivateFieldGet(this, _BootstrapConsent_dataLayer, "f").push({
                readerConsent: consent,
            });
            __classPrivateFieldGet(this, _BootstrapConsent_analytics, "f").state.setReaderConsentAdvertising(consent.advertising);
            __classPrivateFieldGet(this, _BootstrapConsent_analytics, "f").state.setReaderConsentProductAndPhilanthropy(consent.productAndPhilanthropy);
        });
        this.consentHandler.onConsentChanged((consent) => {
            __classPrivateFieldGet(this, _BootstrapConsent_dataLayer, "f").push({
                readerConsent: consent,
            });
            __classPrivateFieldGet(this, _BootstrapConsent_analytics, "f").pubSub.publish("readerConsentChanged", consent);
            __classPrivateFieldGet(this, _BootstrapConsent_analytics, "f").state.setReaderConsentAdvertising(consent.advertising);
            __classPrivateFieldGet(this, _BootstrapConsent_analytics, "f").state.setReaderConsentProductAndPhilanthropy(consent.productAndPhilanthropy);
        });
    }
}
_BootstrapConsent_dataLayer = new WeakMap(), _BootstrapConsent_analytics = new WeakMap();
