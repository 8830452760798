export default class {
    constructor(lpauth) {
        lpauth.onAuthStateChanged(() => {
            if (lpauth.isLoggedIn()) {
                localStorage.setItem("lp.auth.lastUserId", lpauth.currentUser().uid);
                if (this._needToRefreshUserIdAuthDigest()) {
                    this._setUserIdAuthDigest(lpauth);
                }
            }
            if (!lpauth.isLoggedIn()) {
                localStorage.removeItem("lp.auth.lastUserId");
                localStorage.removeItem("lp.auth.lastUserIdAuthDigest");
            }
        });
    }
    getUserId() {
        return localStorage.getItem("lp.auth.lastUserId");
    }
    getUserIdAuthDigest() {
        return localStorage.getItem("lp.auth.lastUserIdAuthDigest");
    }
    _needToRefreshUserIdAuthDigest() {
        return this._isAuthDigestInvalid();
    }
    _isAuthDigestInvalid() {
        return !localStorage.getItem("lp.auth.lastUserIdAuthDigest");
    }
    _setUserIdAuthDigest(lpauth) {
        lpauth.currentUser().getIdToken().then((token) => {
            this._callToGetUserIdAuthDigest(lpauth, token);
        });
    }
    _callToGetUserIdAuthDigest(lpauth, token) {
        const req = new XMLHttpRequest();
        req.onload = () => {
            this._setUserIdAuthDigestFromResponse(req);
        };
        req.onerror = (e) => {
            console.error("Erreur %o lors de la génération du authdigest pour le token %o.", e, token);
        };
        req.open("GET", "/consent/user/" + lpauth.currentUser().uid + "/auth-digest", true);
        req.setRequestHeader("Authorization", "Bearer " + token);
        req.send();
    }
    _setUserIdAuthDigestFromResponse(req) {
        const response = JSON.parse(req.response);
        localStorage.setItem("lp.auth.lastUserIdAuthDigest", response.authDigest);
    }
}
