export default function (publicApiKey, noticeId) {
    window.gdprAppliesGlobally = true;
    const linkElement = document.createElement("link");
    linkElement.rel = "preload";
    linkElement.as = "script";
    const scriptElement = document.createElement("script");
    scriptElement.id = "spcloader";
    scriptElement.type = "text/javascript";
    scriptElement["async"] = true;
    scriptElement.charset = "utf-8";
    const sdkLoaderUrl = "https://sdk.privacy-center.org/" + publicApiKey + "/loader.js?target_type=notice&target=" + noticeId;
    linkElement.href = sdkLoaderUrl;
    scriptElement.src = sdkLoaderUrl;
    const firstScriptElement = document.getElementsByTagName("script")[0];
    firstScriptElement.parentNode.insertBefore(linkElement, firstScriptElement);
    firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement);
}
